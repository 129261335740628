import { createRouter, createWebHistory } from 'vue-router'

const routes = [

    {
        path: "/",
        name: 'DaoBlockView',
        component: () => import('@/view/DaoBlockView.vue')
    },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
